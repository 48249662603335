import React, { Component, useEffect } from 'react'
import { Link } from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Eyecatch from '../components/eyecatch'
import RelatedPosts from '../components/related_posts'
import Ad from '../components/adsense'


class PostTemplate extends Component {
  componentDidMount() {
    const currentPost = this.props.data.wordpressPost
    const content = currentPost.content

    const tocHtml = document.getElementById('toc')
    const contentHtml = document.getElementById('content')
    // console.log(tocHtml, contentHtml)

    // String => Document
    const div = document.createElement('div')
    div.innerHTML = content

    // h2, h3, h4を取得
    const matches = div.querySelectorAll('h2, h3')
    let con = div.outerHTML
    let toc
    let ChildUl

    if(matches.length !== 0) {
      // もくじの入れ物生成
      toc = document.createElement('ol')

      // もくじタイトル挿入
      const title = document.createElement('span')
      title.textContent = 'もくじ'
      title.className = 'toc-title'
      toc.append(title)

      matches.forEach((value, index) => {
        ChildUl = document.createElement('ul')
        let id = value.id
        id = id === '' ? value.textContent : ''
        // htmlタグを取得して小文字に変換
        let tag = value.tagName.toLowerCase()
        // h2, h3タグにidを付与

        con = con.replace(
          value.outerHTML,
          `<${tag} id="${value.textContent}" class="${value.className}" style="${value.style.cssText}">${value.textContent}</${tag}>`,
        )

        // もくじリスト生成
        if (tag === 'h2') {
          var li = document.createElement('li')
          var a = document.createElement('a')
          a.innerHTML = value.textContent
          a.href = '#' + id
          li.appendChild(a)
          toc.append(li)
        }

        if (tag === 'h3') {
          var li = document.createElement('li')
          var a = document.createElement('a')

          // <li><a>h2見出し</a></li>
          var lastH2Li = toc.lastElementChild

          // console.log(lastH2Li)

          if (lastH2Li !== null && lastH2Li.tagName !== 'SPAN') {
            // var lastLi = lastH2Li.lastElementChild
            a.innerHTML = value.textContent
            a.href = '#' + id
            li.appendChild(a)
            ChildUl.appendChild(li)
            lastH2Li.appendChild(ChildUl)
          } else {
            // 空のh2を挿入
            var li = document.createElement('li')
            var a = document.createElement('a')
            li.appendChild(a)
            toc.append(li)
            var lastH2Li = toc.lastElementChild

            var li = document.createElement('li')
            var a = document.createElement('a')
            a.innerHTML = value.textContent
            a.href = '#' + id
            li.appendChild(a)
            ChildUl.append(li)
            lastH2Li.appendChild(ChildUl)
          }
        }
      })
      tocHtml.outerHTML = toc.outerHTML
    }
    contentHtml.outerHTML = con
  }
  render() {
    const currentPost = this.props.data.wordpressPost
    const NavLink = props => {
      if (props.flag) {
        return (
          <div className={props.class}>
            <Link to={props.url}>{props.text}</Link>
          </div>
        )
      } else {
        return ''
      }
    }

    return (
      <Layout>
        <SEO
          title={currentPost.title}
          description={currentPost.excerpt}
          url={
            this.props.data.site.siteMetadata.siteUrl +
            '/post/' +
            currentPost.slug + '/'
          }
          image={
            currentPost.featured_media
              ? this.props.data.site.siteMetadata.siteUrl +
                currentPost.featured_media.localFile.publicURL
              : ''
          }
        />
        <article className={'post'}>
          <header className={'entry-header'}>
            <h1
              dangerouslySetInnerHTML={{ __html: currentPost.title }}
              className={'entry-title'}
            />
            <time className="entry-date">{currentPost.date}</time>
            <div className="cat-links">
              {currentPost.categories.map(category => (
                <Link to={'/category/' + category.slug + '/'} key={category.slug}>
                  <span>{category.name}</span>
                </Link>
              ))}
            </div>

            <div className="tag-links">
              {currentPost.tags
                ? currentPost.tags.map(tag => (
                    <Link to={'/tag/' + tag.slug + '/'} key={tag.slug}>
                      <span>{tag.name}</span>
                    </Link>
                  ))
                : ''}
            </div>
          </header>

          {currentPost.featured_media ? (
            <Eyecatch
              slug={currentPost.slug}
              alt={currentPost.title}
              className={'entry-eyecatch'}
            />
          ) : (
            ''
          )}

          <div className="entry-toc">
            <div id="toc"></div>
          </div>

          <div className="entry-content">
            <div id="content"></div>
          </div>

          <Ad />

          <RelatedPosts
            categories={currentPost.categories}
            slug={currentPost.slug}
          />

          {/* {toc ? (
            <div
              dangerouslySetInnerHTML={{ __html: toc.outerHTML }}
              className={'entry-toc'}
            />
          ) : ('')} */}

          {/* <div
            dangerouslySetInnerHTML={{ __html: con }}
            className={'entry-content'}
          /> */}
          <nav className="post-navigation">
            <div className="nav-links">
              <NavLink
                class={'nav-previous'}
                flag={this.props.pageContext.previous}
                url={'/post/' + this.props.pageContext.previous_slug + '/'}
                text={this.props.pageContext.previous_title}
              />
              <NavLink
                class={'nav-next'}
                flag={this.props.pageContext.next}
                url={'/post/' + this.props.pageContext.next_slug + '/'}
                text={this.props.pageContext.next_title}
              />
            </div>
          </nav>
        </article>
      </Layout>
    )
  }
}

export default PostTemplate

export const pageQuery = graphql`
  query currentPostQuery($wordpress_id: Int!) {
    wordpressPost(wordpress_id: { eq: $wordpress_id }) {
      title
      content
      slug
      excerpt
      date(formatString: "YYYY-MM-DD")
      featured_media {
        localFile {
          childImageSharp {
            sizes {
              src
            }
          }
          publicURL
        }
      }
      tags {
        name
        slug
        wordpress_id
      }
      categories {
        wordpress_id
        slug
        name
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`
