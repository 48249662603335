import React from 'react'
import AdSense from 'react-adsense'

const Ad = () => (
  <div className="ad">
    <AdSense.Google
      client={process.env.GOOGLE_ADSENSE_ID}
      slot=""
      style={{ display: 'block' }}
      responsive="true"
      format="auto"
    />
  </div>
)

export default Ad
