import { StaticQuery, Link } from 'gatsby'
import Noimage from './noimage'
import Eyecatch from './eyecatch'
import PropTypes from 'prop-types'
import React from 'react'

const RelatedPosts = ({ categories }) => (
  <StaticQuery
    query={graphql`
      query {
        allWordpressPost {
          nodes {
            slug
            title
            date(formatString: "YYYY-MM-DD")
            excerpt
            categories {
              slug
            }
            featured_media {
              id
            }
          }
        }
      }
    `}
    render={data => {
      // console.log(
      //   data.allWordpressPost.nodes[0].categories[0],
      //   categories[0].slug,
      // )
      const posts = data.allWordpressPost.nodes.filter(
        n => n.categories[0].slug === categories[0].slug,
      )

      posts.sort(function(a, b) {
        if (a.date > b.date) {
          return -1
        } else {
          return 1
        }
      })

      const sortedPosts = posts.slice(0, 5)

      // console.log(sortedPosts)
      if (!sortedPosts) return

      return (
        <article className="related-posts">
          <h2 className="related-posts-title">関連記事</h2>
          <div className="related-post-entries">
          {sortedPosts.map(post => (
            <section key={post.slug} className={'entry'}>
              <Link to={'/post/' + post.slug + '/'}>
                <div className="entry-left">
                  <figure>
                    {post.featured_media ? (
                      <Eyecatch slug={post.slug} alt={post.title} />
                    ) : (
                      <Noimage alt="noimage" />
                    )}
                  </figure>
                </div>
                <div className="entry-right">
                  <header className="entry-header">
                    <h2 className="entry-title">
                      {post.title}
                    </h2>
                  </header>
                  <div
                  className="entry-excerpt"
                  dangerouslySetInnerHTML={{ __html: post.excerpt }} />
                </div>
              </Link>
            </section>
          ))}
          </div>
        </article>
      )
    }}
  />
)

export default RelatedPosts
